Vue.component("header-component", {
    props: [ 'homepage' ],
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false,
        }
    },
    computed: {
        homeBtnClasses () {
            let classes = [];

            if(this.homepage == 1 && this.scrolled == false){
                classes.push('button--ghost');
                classes.push('button--white');
            }else{
                classes.push('button--primary');
            }

            return classes.join(' ');
        },
        aboutBtnClasses () {
            let classes = [];

            if(this.homepage == 1 && this.scrolled == false && window.innerWidth > 767){
                classes.push('button--white');
            }else{
                classes.push('button--primary');
            }

            return classes.join(' ');
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });
        this.watchScrollPosition();
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            let headerHeight = 0;
            let heroHeight = 0;

            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (document.querySelector('.hero')) {
                heroHeight = document.querySelector('.hero').clientHeight;
            }
            if (document.querySelector('.header')) {
                headerHeight = document.querySelector('.header').clientHeight;
            }

            if (scrollTop > (heroHeight - (headerHeight + 25))) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
    },
});
