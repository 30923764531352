Vue.component('job-media-component', {
    data: function () {
        return {
            id: 'job-media-video'
        }
    },
    mounted: function () {
        this.JobVideo();
    },
    methods: {
        JobVideo: function() {
            let elm = document.querySelector('.job-media_video-wrapper video');
            if (elm) {
                let setup = { controls: false, autoplay: false, preload: "auto", muted: true, loop: true };
                let player = videojs(elm, setup, function(){});
                let button = document.querySelector('.job-media__video-trigger');

                button.addEventListener('click', function (e) {
                    player.currentTime(0.1);
                    player.muted(false);
                    player.play();
                });

                player.ready( function() {
                    player.on("play", function () {
                        document.querySelector('.job-media').classList.add('job-media--aspect-video');
                    });

                    player.on("pause", function () {
                        if(player.scrubbing()){
                            document.querySelector('.job-media').classList.add('job-media--aspect-video');
                        } else {
                            document.querySelector('.job-media').classList.remove('job-media--aspect-video');
                        }
                    });

                    player.on("ended", function () {
                        player.pause();

                        if (player.isFullscreen()) {
                            player.exitFullscreen();
                            player.pause();
                        }

                        document.querySelector('.job-media').classList.remove('job-media--aspect-video');
                    });

                    player.on('fullscreenchange', function () {
                        if (player.isFullscreen()) {
                            player.muted(false);
                        } else {
                            player.muted(true);
                        }
                    });
                });
            }
        },
    },
});
