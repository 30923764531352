Vue.component("home-hero-component", {
    data: function () {
        return {
            id: 'home-hero-video'
        }
    },
    mounted: function () {
        this.toggleHomevideo();
    },
    methods: {
        toggleHomevideo: function(){
            let elm = document.querySelector('#' + this.id);
            if (elm) {
                let player = this.$root.registerVideo(this.id, elm);
                let buttons = document.querySelectorAll('.header__video-trigger');
                let that = this;

                for (var buttonI = 0; buttonI < buttons.length; buttonI++) {
                    buttons[buttonI].addEventListener('click', function (e) {
                        let videoElm = document.querySelector('#' + that.id);
                        videoElm.classList.remove('d-none');

                        if(!player.isFullscreen()){
                            player.requestFullscreen();
                            player.play();
                        }
                    });
                }

                player.on('fullscreenchange', function () {
                    let fullscreen = player.isFullscreen();
                    player.muted(!fullscreen);
                    player.controls(fullscreen);

                    if (fullscreen) {
                        player.currentTime(0.1);
                        player.play();
                    }
                });
            }
        }
    },
});
